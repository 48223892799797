@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-text {
  background-image: linear-gradient(90deg, #1cdf87 0%, #0580e6 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Fait le texte transparent pour montrer le gradient */
  display: inline-block; /* Ou 'block' selon vos besoins */
}