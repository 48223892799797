.chat-container{
  background-color: rgb(244, 244, 141);
  width: 100%;
  height: 100%;
  row-gap: 0;
}

.chat-dialog-container{
  background-color: beige;
  width: 100%;
  height: 100%;
}

.chat-input-container{
  position: relative;
}

.chat-input-container-2{
  display: block;
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.chat-input-flex{
  background-color: rgb(141, 244, 211);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-input {
  min-height: 20px; 
  max-height: 120px; 
  overflow-y: auto;
  resize: none;
}

.chat-message{
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  column-gap: 20px;
}